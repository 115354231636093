import { graphql } from "gatsby";
import React from "react";
import CemeteryGalleryDesktop from "../components/cemetery_gallery/Desktop";
import CemeteryGalleryMobile from "../components/cemetery_gallery/Mobile";
import useDimensions from "../hooks/useDimensions";
import "../css/cementery.css";

const CemeteryGallery = ({ data }) => {
  const [width] = useDimensions();
  const cemeteryGallery = data.sanityPage.cemeteryGalleryTemp;

  return (
    <div className="cementery_bg">
      {width &&
        (width > 768 ? (
          <CemeteryGalleryDesktop data={cemeteryGallery} />
        ) : (
          <CemeteryGalleryMobile data={cemeteryGallery} />
        ))}
    </div>
  );
};

export const query = graphql`
  query MyCemeteryGalleryQuery($slug: String!) {
    sanityPage(slug: { current: { eq: $slug } }) {
      title
      template
      slug {
        current
      }
      seo {
        title
        pagehandle
        ldSchema
        keywords
        description
      }
      cemeteryGalleryTemp {
        back {
          asset {
            altText
            gatsbyImageData(placeholder: BLURRED, formats: WEBP)
          }
        }
        logo {
          asset {
            altText
            gatsbyImageData(placeholder: BLURRED, formats: WEBP)
          }
        }
        cemeteryLogo {
          asset {
            altText
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: WEBP
            )
          }
        }
        scary {
          asset {
            altText
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: WEBP
            )
          }
        }
        overlayAnimation
        gallery {
          image {
            asset {
              altText
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                formats: WEBP
              )
            }
          }
          alt
        }
      }
    }
  }
`;
export default CemeteryGallery;
